import React, { Component } from 'react';
//import{ Link } from 'react-router-dom';

import $ from 'jquery';
//import api from './Api';
import Resizable from 're-resizable';
 
import TableAssets from './../table/Assets';
import FileEdit from './../table/FileEdit';
import Tooltip  from 'rc-tooltip';

require('dotenv').config();

class Phaser extends Component {
  

  constructor(props){

    super(props);
    this.state = {error:[]};
  
    window.phaser = this;
  }

  componentDidMount(){}

  componentDidUpdate(){}
 
 
  
  render() {
    
   

    return (<div> 
			     
			    	 

       
 
    		</div>)


  }


}


export default Phaser;