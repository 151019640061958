import React, { Component } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

var cl,s;
const SortableItem = SortableElement(({value}) => { 
	 
 	cl = 'rdTab myTab react-draggable react-draggable-dragged';
	
	if(value.base === window.studio_blockly.state.openFile){ 
		
		cl = cl + " ativo";

	}

	var acr = "";

	if(window.studio_blockly.state.notsave.indexOf(value.base) === -1){

		acr = acr + "notsave";

	}else{

		acr = acr + "savesave";

	}
	
	return( <li idc={value.id} className={cl} style={s}>
			 
			<span className="rdTabBeforeTitle"><span className="icon-html5"> 

			</span></span>
			<p className={"rdTabTitle myTabTitle "+acr} onClick={ () => window.studio_blockly.getFile(value) }>{value.name}</p>
			<span className="rdTabAfterTitle"><span></span></span>
			<span style={{zIndex:'99999'}} className="rdTabCloseIcon tabCloseIcon" onClick={ () => window.studio_blockly.closeFile(value) }>×</span>
			<span className="rdTabBefore myTabBefore"></span>
			<span className="rdTabAfter myTabAfter">  </span>

		</li>)

});

const SortableList = SortableContainer(({items,removeFile}) => {
  
	return (<ul className="fileListEs">{items.map((value,index) => {
		 
		return(<SortableItem onClick={ () => console.log('criou') } key={`item-${index}`} index={index} value={value} />)

	})}</ul>);

});

class Files extends Component {
	 
	constructor(props){ 
	
		super(props); 
		this.onSortFileEnd = this.onSortFileEnd.bind(this);

		window.tablefiles = this;
		
	}

	async onSortFileEnd(data){

		console.log(data);
		var openFiles = this.props.openFiles;
		openFiles = arrayMove(openFiles,data.oldIndex,data.newIndex);
		await this.props.setState({openFiles});

		window.studio_blockly.saveSettings();

	}

	render() {
 
	    return (<div className="rdTabWrapper myWrapper">

				<SortableList onSortEnd={this.onSortFileEnd} items={this.props.openFiles} distance={10} lockAxis="x" axis="x" helperClass="rdTab myTab"  />

				{/* items={window.studio_blockly.state.filesList} */}

				<div hidden style={{cursor:'pointer'}} className="maisFile" onClick={ () => {}}>+</div>

			</div>);

  	}


}

export default Files;
