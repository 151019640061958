import React, { Component } from 'react';
import $ from 'jquery';

//import './assets/login-v3.default.css';
//import './assets/vendors.bundle.css';
import api from './../Api';
//import Sortable from 'sortablejs';

//import Swal from 'sweetalert2';

//import Login from './Login';
//import Head from './Head';
import { toast } from 'react-toastify';
 
class Configuration extends Component {

	constructor(props){

    	super(props);

    	this.state = {mostrar:false};
    	this.resetConfiguration = this.resetConfiguration.bind(this);
    	this.saveConfiguration = this.saveConfiguration.bind(this);
    	window.gameConfigurationModal = this;

    }

	componentDidMount(){
 
	  	this.setState({mostrar:true});
	  	
	}

	resetConfiguration(){

		this.setState({mostrar:false});
		setTimeout(function(){ this.setState({mostrar:true}); }.bind(this), 10); 
		
		window.$("#configurationModal").modal();

	}

	//para salvar as novas configurações
	saveConfiguration(event){	

		event.preventDefault();

		var dados = {name:$('#nameGameInput').val(),description:$("#descriptionGame").val(),defaultEditor:$("#defaultEditor").val(),phaserwidth:$("#exampleInputEmail4").val(),phaserheigth:$("#exampleInputEmail3").val()}

		$("#divIframePhaser").css('width',  $("#exampleInputEmail4").val()+'px');
		$("#divIframePhaser").css('height', $("#exampleInputEmail3").val()+'px');

		console.log('saveSettings');
		console.log(dados);

		api.saveSettings(window.studio.props.match.params.game,dados).then((res) => {

			toast.success('As configurações foram salvas.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
			window.$('div').modal('hide');
        	window.studio_blockly.setState({game:res.data});
        	window.phaser.setState({a:1});

	    }).catch(error => {

	    	toast.error('Não foi possivel salvas as configurações', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
			
	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });

	}

	render(){

		return(<>

			<div className="modal fade" id="configurationModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
			  <div className="modal-dialog" role="document">
			    <div className="modal-content">
					
					<div className="modal-header">
				        <h5 className="modal-title">Configurações do projeto</h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
			      	</div>
					
					{ (window.studio_blockly.state.game.name !== undefined && this.state.mostrar === true) &&
						
						<form id="configurationForm" onSubmit={ (event) => this.saveConfiguration(event) }>
				    	
					    	<div className="modal-body">

								<div className="form-group">
									<label htmlFor="exampleInputEmail1">Nome:</label>
									<input type="text" id="nameGameInput" name="name" defaultValue={ window.studio_blockly.state.game.name } className="form-control" aria-describedby="emailHelp" />
								</div>

								<div className="form-group" hidden>
									<label htmlFor="exampleInputEmail1">Editor padrão:</label>
 

									<select id="defaultEditor" defaultValue={ window.studio_blockly.state.game.defaultEditor.toString() } className="form-control">

										{ 	

											window.defaultEditorOptions.map(function(item, i){
											  
											  return <option key={i} value={i} >{item}</option>

											})

										}

									</select>
								</div>

								<div className="form-group">
									<label htmlFor="exampleInputEmail1">Descrição:</label>
									<textarea type="text" name="name" className="form-control" id="descriptionGame" aria-describedby="emailHelp" defaultValue={window.studio_blockly.state.game.description}></textarea>
								</div>

								<div className="row">

			                    	<div className="col-md-4">
			                    		<div className="form-group">
					                      <label htmlFor="exampleInputEmail3">Altura:</label>
					                      <input required defaultValue={window.studio_blockly.state.game.phaserheigth} type="text" name="name"  className="form-control" id="exampleInputEmail3" aria-describedby="emailHelp" /> 
					                    </div>
			                    	</div>

			                    	<div className="col-md-4">
				                    	<div className="form-group">
					                      <label htmlFor="exampleInputEmail4">Largura:</label>
					                      <input required defaultValue={window.studio_blockly.state.game.phaserwidth} type="text" name="name"  className="form-control" id="exampleInputEmail4" aria-describedby="emailHelp" /> 
					                    </div>
				                    </div>

			                    	<div className="col-md-4">
				                    	<div className="form-group">
					                      <label htmlFor="exampleInputEmail5">Tipo de física:</label>
					                      <select defaultValue={window.studio_blockly.state.game.physic} type="text" name="name" required="" className="form-control" id="exampleInputEmail5" aria-describedby="emailHelp">

					                      	<option value="arcade">Arcade</option>

					                      </select>
					                    </div>
				                    </div>

			                    </div>

					      	</div>

					      	<div className="modal-footer">

					      		<button onClick={ () => window.studio.deleteGame() } type="button" className="btn btn-danger" data-dismiss="modal" style={{float:'left'}}>Remover game</button>

					      		<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
					        	<button type="submit" className="btn btn-primary">Salvar alterações</button>
					      	</div>

				      	</form>

			      	}
			     
			    </div>
			  </div>
			</div>
			</>)

	}


}

export default Configuration;