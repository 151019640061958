
import React, { Component } from 'react';
import{ Link } from 'react-router-dom';
import $ from 'jquery';
import api from './Api';
import Swal from 'sweetalert2';
import Blockly from './studio/Blockly';
import Phaser from './studio/Phaser';
import ConfigurationModal from './modal/Configuration';
import AssocitionModal from './modal/AssocitionModal';
import AnimationModal from './modal/AnimationModal';
import ImportAssetScene from './modal/ImportAssetScene';
import AcerEditorViwer from './studio/AcerEditorViwer';
import UploadAssetModal from './modal/UploadAssetModal';
import ImportAsseAsset from './modal/ImportAsseAsset';

import './studio/css/studio.css';
import './studio/css/tabs.css';
import './studio/css/assets.css';
import './studio/css/log.css'; 
import './studio/css/customConfigAcer.css'; 

import Tooltip  from 'rc-tooltip';
import { toast } from 'react-toastify';

require('dotenv').config();

class Studio extends Component {
  

  constructor(props){

    super(props);
    
    this.state = {
      debug:0,
      hiddenHeader:0,
      hiddenFiles:0,
      hiddenAssets:0,
      hiddenConsole:0,
      hiddenBarFiles:0,
      description:null,
      defaultCanvasWidth:null,
      defaultCanvasHeigth:null,
      defaultFilesCreate:{},
      hiddenCanvasSize:0,
      getOptions:0,
      mostraResto:0,
      hiddenResizeCanvas:0,
      hiddenTrash:0,
      hiddenCloseTab:0,
      data:{},
      url_master:"index.html"};

    this.resetWorksPace = this.resetWorksPace.bind(this); 
    this.externalLink = this.externalLink.bind(this);
    this.deleteGame = this.deleteGame.bind(this);

    window.studio = this;

  }

  //para mostar o canvas do phaser
  showCanvas(){ $('.optionHeaderStudio').removeClass('ativo');$('.optionHeaderStudio[type="showCanvas"]').addClass('ativo');$('#divPhaser').fadeIn(0); $("#divAcerEditor").fadeOut(0); }

 

  //para resetar o tamanho da workspace
  resetWorksPace(){ 
    
  }



  componentDidMount(){

    $("#loadingTxt").html('Carregando studio');

    //caso esteja informado opcao, busca os dados dela na api
    if(this.props.match.params.options === undefined){

        //console.log('deu undefined');
        this.setState({mostraResto:true});

    }else{

      api.getStudioOptions(this.props.match.params.options).then((res) => {

          res.data.mostraResto = true;
          this.setState(res.data);
          
      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });
    
    }
  }

  //para delar o game
  deleteGame(){
 
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Depois de excluir é impossível recuperar.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não cancelar'
    }).then((result) => {

      if(result.value){
   
        //remove o arquivo na api
        api.deleteGame(this.props.match.params.game).then((res) => {
          
          toast.success('O projeto foi removido.', {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true});
          this.props.history.push('/');

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
      
        });

        console.log('removido');

      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });

  }

  resetWidth(){

		var largura = $(window).width(); 
    var b = largura * 0.7;
    $("#resizeBlockly").css('width',b+'px');
    
    setTimeout(function(){

      window.studio_blockly.saveSettings();

    }, 100);

  }
  
  externalLink(){

    window.open(sessionStorage.getItem('backend')+'/uploads/users/'+this.state.data.user+'/'+this.state.data.id+'/index.html', '_blank');

  }
  
  refreshIframe(){

    var url = $("#iframeTp").attr('src');
    $("#iframeTp").attr('src','');
    $("#iframeTp").attr('src',url);

  }

  render(){

    var Hei = window.innerHeight;
    Hei = Hei - 60;
 
    var showhelp = false;
    var qq = window.parseQueryString();

    if(qq.video !== undefined && qq.vh !== undefined && qq.vw !== undefined){ showhelp = true; }

    return (<>
          
          <style>{' body{ overflow: hidden; }  .iframeAsset{ height: '+Hei+'px; } '}</style>

          <link rel='stylesheet' href='/css/studio.css' type='text/css' media='all' />
  
          <div id="pageStudio" style={{display:'flex'}}>

            <div className="studio_options">

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Voltar a página inicial</span>}>
              <div className="option option_1 hvr-hang">   <Link to="/"><img alt="" src="/media/home_user_places.png" /></Link> </div>
              </Tooltip> 

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Configurações do projeto</span>}>
                <div onClick={ () => window.$("#editaProjetoModal").modal() } className="option option_3 hvr-hang"  > <img alt="" src="/media/builder_png_px_website.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Resetar largura da workspace</span>}>
                <div onClick={ () => this.resetWidth() } className="option option_3 hvr-hang"> <img alt="" src="/media/synchronize_sync_refresh.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Abrir em nova aba</span>}>
                <div onClick={ () => this.externalLink() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/tab_new_add_plus.png" /> </div>
              </Tooltip>

              <div className="modal fade" id="assetsStoreModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                  <div className="modal-dialog" role="document" style={{maxWidth:'initial'}}>
                      <div className="modal-content">

                        <style>{'.fecharAsset{ cursor:pointer; position: absolute; left: calc(100% - 55px); z-index: 9999; font-size: 29px; top: 62px; color: #fff; }'}</style>
                        <div className="fecharAsset" onClick={ () => window.$('#assetsStoreModal').modal('hide') }><i className="fas fa-times"></i></div>
                        <iframe title="store" className="iframeAsset" src={window.urlstore} frameBorder="0"></iframe>

                      </div>
                  </div>
              </div>
               
              <font id="buttonAssociaFile" style={{display:'none'}}>
                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Associção de arquivo</span>}>
                  <div onClick={ () => window.AssocitionModal.openAssocitionModal() } className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/seo-web-code-icon.png" /> </div>
                </Tooltip>
              </font>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Deletar projeto</span>}>
                <div onClick={ () => this.deleteGame() } style={{display:'none'}} className="option option_3 hvr-hang"> <img style={{height:'36px'}} alt="" src="/media/trashcan_empty_alt.png" /> </div>
              </Tooltip>
              
            </div>

            <div className="studio_2" style={{display:'flex'}}>

              <div id="divBlockly">

                  { 
                    (this.state.mostraResto) &&
                    <Blockly resetWidth={ () => this.resetWidth() } state={this.state} setState={(data) => this.setState(data) } {...this.props} /> 
                  }

              </div>

            </div>
 
            <div style={{backgroundColor: '#282f34',marginLeft:'18px',width:'100%'}}>

              <div className="headerLeftStudio">
                             
                  <div style={{textAlign:'left',paddingLeft:'15px'}} className="optionHeaderStudio ativo" type="showCanvas" onClick={ () => this.showCanvas() }>
                    <font>PREVIEW</font>
                  </div>

                  {

                    (showhelp === true) &&
                    <button onClick={() => window.studio_blockly.setState({showVideo:true}) } class="btn btn-danger btn-sm flash t2 midix infinite" style={{
                        position:'absolute',
                        left:'calc(100% - 340px)',
                        height:'21px',
                        paddingTop:'1px',
                        fontSize:'11px',
                        top:'5px'}}
                    ><i class="fas fa-mitten"></i> Ajuda</button>

                  }
                  
                  <label class="containerCheck" style={{left:'calc(100% - 260px)'}}><font style={{color:"#fff"}}>Autosave (5s)</font>
                    <input onChange={ () => window.studio_blockly.saveSettings() } id="input_autosave" type="checkbox" />
                    <span class="checkmark"></span>
                  </label>

                  <label class="containerCheck"><font style={{color:"#fff"}}>RealTime</font>
                    <input onChange={ () => window.studio_blockly.saveSettings() } id="input_tempo_real" type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
  
              </div>

              <div style={{display:'flex'}}>
                <div style={{position:'absolute'}}><i onClick={ () => this.refreshIframe() } class="fas fa-sync-alt" style={{marginLeft:'10px',marginRight:'10px',color:'#fff',cursor:'pointer'}}></i></div>
                <div style={{width:'100%'}}>
                  <input id="input_url" className="input_change_link" onChange={ async (event) => {

                    await this.setState({url_master:event.target.value});
                    this.refreshIframe();

                  }} value={this.state.url_master} />
                </div>
              </div>
              
              <div>
                <div id="divPhaser">

                  <iframe id="iframeTp" src={sessionStorage.getItem('backend')+'/uploads/users/'+this.state.data.user+'/'+this.state.data.id+'/'+$("#input_url").val()}></iframe>
                    
                </div> 
              </div>
            </div>

        </div>

      

      </>)


  }


}

export default Studio;