 
import React, { Component } from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';
import api from './../Api';
import Swal from 'sweetalert2';
import TableFiles from './../table/Files';
import arrayMove from 'array-move';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Resizable from 're-resizable';  
import {Treebeard} from 'react-treebeard'; 
import { ControlledEditor } from '@monaco-editor/react';
import AceEditor from 'react-ace';
import Draggable from 'react-draggable';
import axios from "axios";

import 'brace/mode/javascript';
import 'brace/mode/html';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';
import 'brace/theme/xcode';
import 'brace/snippets/html';
import 'brace/ext/language_tools';
 
class Blockly extends Component {

	constructor(props){ 
		
		super(props); 
 
		this.state = {game:{},notsave:[],dirDirClicked:"",showVideo:false};

		this.state.data = {
			name: 'root',
			toggled: true,
			children:[],
			defaultAceEditorValue:"", 
		};

	 

		this.state.openFiles = [];
   
		this.state.folder = {dir:""};
		this.state.file = {dir:""};


		var qq = window.parseQueryString();
		if(qq.video !== undefined && qq.vh !== undefined && qq.vw !== undefined){

			this.state.showVideo = true;
			this.state.videourl = qq.video;
			this.state.vh = qq.vh;
			this.state.vw = qq.vw;

		}

		this.carregaDados = this.carregaDados.bind(this); 
		this.carregaTudoV1 = this.carregaTudoV1.bind(this);
		this.carregaTudoV2 = this.carregaTudoV2.bind(this);
		this.onToggle = this.onToggle.bind(this);
		this.getTreeFolder = this.getTreeFolder.bind(this);
		this.aceEditorChange = this.aceEditorChange.bind(this);
		this.resizeParts = this.resizeParts.bind(this);
		this.getFile = this.getFile.bind(this);
		this.closeFile = this.closeFile.bind(this);
		this.saveFile = this.saveFile.bind(this);
		this.saveFilePress = this.saveFilePress.bind(this);
		this.saveSettings = this.saveSettings.bind(this);
		this.postFolder = this.postFolder.bind(this);
		this.newFolder = this.newFolder.bind(this);
		this.postFile = this.postFile.bind(this);
		this.newFile = this.newFile.bind(this);

		this.renameFile = this.renameFile.bind(this);
		this.postRenameFile = this.postRenameFile.bind(this);
		this.deleteFile = this.deleteFile.bind(this);

		this.removeProject = this.removeProject.bind(this);
		this.newFileUpload = this.newFileUpload.bind(this);

		this.postNewFile = this.postNewFile.bind(this);

		window.studio_blockly = this; 

	}



	async postNewFile(){

		let formData = new FormData();

		var file = window.$("#fileup")[0].files;

		if(file.length === 0){  
 			
			await this.setState({erro:""});
			alert('Você deve escolher um arquivo.');
			return false;

		}


		formData.append('files', file[0]); 
		formData.append('dir', $("#dir_upload").val() );
		
		axios.post(sessionStorage.getItem('backend')+'/game/upload/'+this.props.match.params.game,formData,{
			headers:{session: sessionStorage.getItem('session'),'Content-Type': 'multipart/form-data'}
		}
		).then(function(){

			window.$('div').modal('hide');
			this.getTreeFolder(true);
			toast.success("O arquivo foi enviado.",{position: toast.POSITION.TOP_RIGHT}); 

		}.bind(this))
		.catch(function(erro){
			
			console.log(erro);
			if(erro.response !== undefined){

				if(erro.response.data !== undefined){

					alert(erro.response.data.msg);

				}

			}

		});

	}

	async newFileUpload(){

		window.$('#newFileModal').modal();
		
		var la = this.state.dirDirClicked;
		if(la === ""){ la = "/"; }

		document.getElementById("formUpFile").reset();
		window.$("#dir_upload").val(la);

	}

	//Para remover um projeto
	removeProject(){

		Swal.fire({
			title: 'Você tem certeza?',
			text: "O projeto inteiro com todos os arquivos irão ser excluídos.",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sim',
			cancelButtonText: 'Não cancelar'
		  }).then((result) => {
	  
			if(result.value){
	   
			  api.deleteGame(this.props.match.params.game).then(async (res) => {
				
				window.$('div').modal('hide');
				this.props.history.push('/');
				  
			  }).catch(error => {
	  
				  //console.log(error.data);
				  //console.log(error);
				  //console.log(error.response);
				  //return false;
	  
			  });
	  
	  
			}else if (result.dismiss === Swal.DismissReason.cancel) {
			 
	  
			}
	  
		});

	}

	//Para deletar um arquivo
	deleteFile(){

		Swal.fire({
			title: 'Você tem certeza?',
			text: "Não será possível recuperar. "+this.state.dirClicked+" será removido.",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sim',
			cancelButtonText: 'Não cancelar'
		  }).then((result) => {
	  
			if(result.value){
	   
			  api.deleteAlgo(this.props.match.params.game,{dir:this.state.dirClicked}).then(async (res) => {
				
				toast.success("Removido com sucesso!",{position: toast.POSITION.TOP_RIGHT});

				await this.getTreeFolder(true);
				await this.saveSettings();
				  
			  }).catch(error => {
	  
				  //console.log(error.data);
				  //console.log(error);
				  //console.log(error.response);
				  //return false;
	  
			  });
	  
	  
			}else if (result.dismiss === Swal.DismissReason.cancel) {
			 
	  
			}
	  
		  });

	}


	//Para renomear o arquivo
	renameFile(){

		window.$("#renameModal").modal();

		var dirClicked = this.state.dirClicked;
		dirClicked = dirClicked.split('/');

		this.setState({rename:dirClicked[ (dirClicked.length - 1) ]});		

	}

	//Para postar o rename de arquivo
	postRenameFile(){

		var obj = {};
		obj.dir = this.state.dirClicked;
		obj.rename = this.state.rename;
		
		//saveSettings
		api.postRenameFile(this.props.match.params.game,obj).then(async (res) => {
  
			window.$("div").modal('hide');

			await this.getTreeFolder(true);
			await this.saveSettings();

			toast.success("Renomeado com sucesso!",{position: toast.POSITION.TOP_RIGHT});
			
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){
	        	if(error.response.status !== undefined){
					//console.log(error.response); 
					toast.error(error.response.data.msg,{position: toast.POSITION.TOP_RIGHT});
					
	        	}
	        }

	        return false;
	  
		});
		 


	}


	
	//Para cadastrar um arquivo
	postFile(){

		var obj = {};
		obj.dir = this.state.file.dir;
		
		//saveSettings
		api.postFile(this.props.match.params.game,obj).then(async (res) => {
 
			console.log(res.data);
			window.$("div").modal('hide');
			await this.getTreeFolder(true);
			await this.saveSettings();
			
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){
	        	if(error.response.status !== undefined){
					//console.log(error.response); 
					toast.error(error.response.data.msg,{position: toast.POSITION.TOP_RIGHT});
					
	        	}
	        }

	        return false;
	  
		});
	 

	}
	
	postFolder(){

		var obj = {};
		obj.dir = this.state.folder.dir;
		
		//saveSettings
		api.postFolder(this.props.match.params.game,obj).then(async (res) => {
 
			console.log(res.data);
			window.$("div").modal('hide');
			await this.getTreeFolder(true);
			await this.saveSettings();
			
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){
	        	if(error.response.status !== undefined){
					//console.log(error.response); 
					toast.error(error.response.data.msg,{position: toast.POSITION.TOP_RIGHT});
					
	        	}
	        }

	        return false;
	  
		});
 

	}



	newFile(){

		window.$('#novaFileModal').modal();
		this.state.file.dir = "";
		this.setState({a:''});

	}

	newFolder(){

		window.$('#novaPastaModal').modal();
		this.state.folder.dir = "";
		this.setState({a:''});

	}

	//Para salvar os dados
	saveSettings(){

		var autosave = "0";
		var realtime = "0";

		if($('#input_autosave').is(':checked')){ autosave = 1; }
		if($('#input_tempo_real').is(':checked')){ realtime = 1; }

		 

		var obj = {
			name:this.state.game.name,
			description:this.state.game.description,
			widthcanvas:$("#resizeBlockly").css('width'),
			fontSize:"16px",
			autosave:autosave,
			realtime:realtime,
			treecache:JSON.stringify(this.state.data),
			tabcache:JSON.stringify(this.state.openFiles),
			openFile:this.state.openFile,
		};

		
		//saveSettings
		api.saveSettings(this.props.match.params.game,obj).then(async (res) => {
 
			console.log(res.data);
			window.$("div").modal('hide');
			
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){

	        	if(error.response.status !== undefined){

					//console.log(error.response); 
					toast.warn(error.response.data.msg,{position: toast.POSITION.TOP_RIGHT});
					
	        	}

	        }

	        return false;
	  
		});

		
	}

	checkWidth(){

		var larguraMaxima = $(window).width();
		var widthcanvas = parseInt(this.state.game.widthcanvas);

		if(widthcanvas > larguraMaxima){

			this.props.resetWidth();

		}

	}

	//Para pegar o arquivo
	async getFile(file){

		await this.setState({enableSave:false});

		var openFiles = this.state.openFiles;
		if(openFiles.map(function(x){ return x.base; }).indexOf(file.base) === -1){
			
			openFiles.push(file);
		
		}
		
		console.log(openFiles);
		console.log(file.base);

		var base = file.base+"";
		
		//this.setState({openFiles:openFiles,openFile:file.base});
		this.setState({openFiles:openFiles});
		this.setState({openFile:base});
		this.setState({a:'aa'});
		 

		api.getFile(this.props.match.params.game,file.base).then(async (res) => {
 
			await this.setState({defaultAceEditorValue:res.data});
			await this.setState({enableSave:true});
			await this.setState({dirClicked:base});
			
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){

	        	if(error.response.status !== undefined){

					if(error.response.status === 400){

						toast.error(error.response.data.msg,{position: toast.POSITION.TOP_RIGHT});

					}
	        		if(error.response.status === 404){ $("#loadingTxt").html('O Projeto não foi encontrado.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 401){ $("#loadingTxt").html('Você não esta logado na plafatorma.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 403){ $("#loadingTxt").html(error.response.data.msg); $("#page-loader").find('.loader-icon').fadeOut(0); }

	        	}

	        }

	        return false;
	  
		});
		

	}

	async getTreeFolder(force){

		await this.setState({showTree:false});

		if(force !== true){

			await this.setState({data:this.state.game.treecache});
			await this.setState({showTree:true});

			return false;

		}

		var a = await api.getTreeFolder(window.studio.props.match.params.game).then(async (res) => {

			console.log(res.data);
			res.data.toggled = true;
			res.data.name = "Arquivos";
			
			await this.setState({data:res.data});
			await this.setState({showTree:true});

			this.resizeParts();

			return res.data;

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){

	        	if(error.response.status !== undefined){

	        		if(error.response.status === 404){ $("#loadingTxt").html('O Projeto não foi encontrado.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 401){ $("#loadingTxt").html('Você não esta logado na plafatorma.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 403){ $("#loadingTxt").html(error.response.data.msg); $("#page-loader").find('.loader-icon').fadeOut(0); }

	        	}

	        }

	        return false;
	  
	    });
 
		return a;

	}

	//para pegar os dados do game
	async carregaDados(ignoreToolBox){

		$("#loadingTxt").html('Carregando dados do projeto.');

		var carregar = await api.getGame(window.studio.props.match.params.game).then(async (res) => {
			
			console.log(res.data);
			
			await this.setState({game:res.data});
			await this.props.setState({data:res.data})
  
			$("#divIframePhaser").css('width', res.data.phaserwidth+'px');
			$("#divIframePhaser").css('height', res.data.phaserheigth+'px');
			
			this.checkWidth();
			
			$("#resizeBlockly").css('width',this.state.game.widthcanvas+'px');
			
			await this.setState({openFiles:res.data.tabcache});
			await this.setState({openFile:res.data.openFile});

			for(let index = 0; index < res.data.tabcache.length; index++) {
				
				if(res.data.tabcache[index].base === res.data.openFile){

					this.getFile(res.data.tabcache[index]);

				}
				
			}
			
			if(parseInt(res.data.autosave) === 1){ $("#input_autosave").prop('checked',true); }
			if(parseInt(res.data.realtime) === 1){ $("#input_tempo_real").prop('checked',true); }



			//Verifica tamanho da tela
			if(window.$(window).width() <= parseInt(res.data.widthcanvas)){

				window.studio.resetWidth();
				this.getTreeFolder(true);

			}

			if(res.data.openFile === ""){

				this.setState({openFile:undefined});

			}

			return true;
			
	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	        if(error.response !== undefined){

	        	if(error.response.status !== undefined){

	        		if(error.response.status === 404){ $("#loadingTxt").html('O Projeto não foi encontrado.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 401){ $("#loadingTxt").html('Você não esta logado na plafatorma.'); $("#page-loader").find('.loader-icon').fadeOut(0); }
	        		if(error.response.status === 403){ $("#loadingTxt").html(error.response.data.msg); $("#page-loader").find('.loader-icon').fadeOut(0); }

	        	}

	        }

	        return false;
	  
	    });

	    return carregar;

	}

	componentWillMount(){ 

		//antes de montar, mostro a tela de loading
		window.app.loading(true); 

	}

	keypress(event){

		console.log(event);

	}

	saveFilePress(event){
		

		if(event.code !== "ControlRight" && event.code !== "ControlLeft"){

			return false;

		}

 
		console.log('salva');
		this.saveFile();
		

	}

	//Para salvar automaticamente
	autosave(){


		if($("#input_autosave").is(':checked')){

			if(this.state.enableSave === true){

				this.saveFile();

			}

		}

		setTimeout(function(){ 
			
			this.autosave();
		
		}.bind(this), 5000);

	}

	componentDidMount(){

		setTimeout(function(){ this.resizeParts() }.bind(this), 100);
		setTimeout(function(){ this.resizeParts() }.bind(this), 300);
		setTimeout(function(){ this.resizeParts() }.bind(this), 400);
		setTimeout(function(){ this.resizeParts() }.bind(this), 500);

		this.autosave();

		window.addEventListener('resize',this.resizeParts);
		
		document.addEventListener('keydown', this.saveFilePress, false);

		//document.onkeypress = verificaBotao; 
		
		var tempo;
		//escondo todo studio.... para garantir que ninguem veja nada
		$("#pageStudio").css('opacity',0);

		if(this.state.log)
		console.log('componentDidMount');

		//se a tela de loading ja estiver la.... do um timeout
		if($('#page-loader').is(':visible')){ tempo = 0; }else{ tempo = 3000; }

		setTimeout(function(){ 

			//busca os dados do game
			this.carregaTudoV1();

		}.bind(this), tempo);

		$("#loadingTxt").html('Carregando dados do projeto');

		 
 
	}
  
	async carregaTudoV1(){

		console.log('carregador acionado');

		var game = await this.carregaDados();
		if(game !== true){ return false; }

		await this.getTreeFolder();

		setTimeout(function(){ this.carregaTudoV2(); }.bind(this), 500);


	}


	async carregaTudoV2(){
		
		$("#pageStudio").css('opacity',1);
		
 		//para setar a fonte do ace editor
		  
		window.app.loading(false);

	}

	//sempre que der update no state
	componentDidUpdate(){}

	componentWillUnmount(){

		window.removeEventListener('resize', this.resizeParts);
		document.removeEventListener('keydown' ,this.saveFilePress);

	}

	//Ao clicar em algo na arvore de arquivos
	async onToggle(node,toggled){
		
		if(node.type === "folder"){

			this.setState({dirDirClicked:node.base,showUploadFile:true});
			
		}else{

			this.setState({showUploadFile:false});

		}

        //if(this.state.cursor){ this.state.cursor.active = false; }
		node.active = true;
		
		if(node.children){node.toggled = toggled;}
		
		await this.setState({cursor:node,dirClicked:node.base});
		
		//Caso seja um arquivo pega os dados do arquivo.
		if(node.type === "file"){
  
			this.getFile(node);

		}
		
	}
	
	//Para redimencionar as coisas 
	resizeParts(){

		var altura = $("body").height();;
		$(".treefolder").find('ul').css('height',(altura - 100));

		this.setState({altura:(altura - 100)});
		
		$("#iframeTp").css('width','100%');
		$("#iframeTp").css('border','none');
		$("#iframeTp").css('height',(altura - 32)+'px');
		$("#iframeTp").css('background-color','#fff');
  

	}

	//Quando digita algo no ace editor
	aceEditorChange(newValue){

		this.setState({defaultAceEditorValue:newValue});
		
		var notsave = this.state.notsave;
		if(notsave.indexOf(this.state.openFile) === -1){

			notsave.push(this.state.openFile);
			this.setState({notsave});

		}

		if($('#input_tempo_real').is(':checked') === true){

			this.saveFile();

		}
		
	}

	//Para fechar um arquivo
	async closeFile(file){

		var index = this.state.openFiles.map(function(x){ return x.base; }).indexOf(file.base);
		var openFiles = this.state.openFiles;
		
		console.log(openFiles);
		console.log(file);
		console.log(index);

		if(openFiles[(index +1)] !== undefined){

			await this.setState({openFile:openFiles[(index +1)].base});
			this.getFile(openFiles[(index+1)]);

		}else{

			if(openFiles[(index-1)] !== undefined){
				
				await this.setState({openFile:openFiles[(index-1)].base});
				this.getFile(openFiles[(index-1)]);

			}else{

				this.setState({openFile:undefined})

			}

		}
		

		if(index !== -1){

			openFiles.splice(index,1);
			await this.setState({openFiles});

		}

		window.studio_blockly.saveSettings()

	}

	//Para salvar o arquivo
	async saveFile(){

		if(this.state.openFile === undefined){ return false; }

		//para evitar problema
		if(this.state.enableSave !== true){ return false; }

		var a = await api.saveFile(this.props.match.params.game,this.state.openFile,{value:this.state.defaultAceEditorValue}).then(async (res) => {

			if($('#input_tempo_real').is(':checked')){
				
				var url = $("#iframeTp").attr('src');
				$("#iframeTp").attr('src','');
				$("#iframeTp").attr('src',url);

			}
			

			var notsave = this.state.notsave;
			var index = notsave.indexOf(this.state.openFile);
			notsave.splice(index,1);
			this.setState({notsave});
			
			return true;

	    }).catch(error => {

			console.log(error.data);
	        console.log(error);
			console.log(error.response);

			if(error.response !== undefined){

	        	if(error.response.status !== undefined){

					if(error.response.status === 404){
						
						toast.error(error.response.data.msg,{position: toast.POSITION.TOP_RIGHT});

					}

	        		if(error.response.status === 400){
						
						toast.error(error.response.data.msg,{position: toast.POSITION.TOP_RIGHT});

					}
	        	 
	        	}

	        }

			return false;
			
		});
		
 
	}
 
	render() {

		const handleEditorChange = (ev, value) => {

			console.log(ev, value);
			
		}

		var ext = "html";
		var isimg = false;
		if(this.state.openFile !== undefined){
		
			try{

				if(this.state.openFile !== ""){

					var ext = this.state.openFile.split('.').pop();
		 			if(ext === "html"){ ext = "html"; }
					if(ext === "js"){ ext = "javascript"; }

					if(ext === "jpg"){ isimg = true; }
					if(ext === "png"){ isimg = true; }
					if(ext === "jpeg"){ isimg = true; }
  
					if(isimg === true){
						
						this.state.enableSave = false;
					
					}else{
						
						this.state.enableSave = true; 
					
					}

				}
							  
			}catch(error){}
		  
		}
		

		var styleRes = {top: {display:'none'},topLeft: {display:'none'},left: {display:'none'},bottomLeft: {display:'none'},bottom: {display:'none'},bottomRight: {display:'none'},right: {marginTop:'36px',flex: '0 0 auto',width: '18px', background: 'url(https://raw.githubusercontent.com/RickStrahl/jquery-resizable/master/assets/vsizegrip.png) center center no-repeat #535353',minHeight: '200px',cursor: 'col-resize',zIndex: '1',right: '-18px'},topRight: {display:'none'}};

		return(<React.Fragment>
  

		{

				(this.state.showVideo === true) &&
				<React.Fragment>
					<div className="div_drag"> 
						<style>{' .div_drag .react-draggable{ background-image: linear-gradient(rgba(69, 69, 69, 0), rgba(0, 0, 0, 0)); } '}</style>
						<Draggable axis="both" handle=".handle" defaultPosition={{x:window.$(window).width()*0.7,y: window.$(window).height()*0.5 }} grid={[0.1,0.1]} scale={1}>
															
								<div style={{zIndex:"999",position:'absolute'}}>
									<center style={{width:this.state.vw+'px'}}>

										<div style={{boxShadow:"rgb(255, 255, 255) 0px 1px 5px -2px",display:'flex',width:"220px",borderRadius:'10px 10px 0px 0px',backgroundColor:"#252526"}}>
											<div style={{color:"#fff",cursor:"grab",width:'100px'}} className="handle" >Segure e arraste</div>
											<div onClick={() => this.setState({showVideo:false})} style={{cursor:'pointer',color:"#fff",marginLeft:'50px'}}><i class="far fa-times-circle"></i> Fechar</div>
										</div>
										<iframe style={{borderRadius:'10px',boxShadow:'0px 1px 5px -2px #ffffff'}} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
										
										width={this.state.vw} 
										height={this.state.vh} 
										
										src={this.state.videourl} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
								
									</center>
								</div>

						</Draggable>
					</div>
				</React.Fragment>

			}


			<div style={{display:'flex'}}>

				{
					( this.state.game.widthcanvas !== undefined) &&
					<Resizable id="resizeBlockly" defaultSize={{ width:this.state.game.widthcanvas+'px' }} handleStyles={ styleRes } handleWrapperClass="splitter" handleClasses="splitter" onResize={(e, direction, ref, d) => {   }} onResizeStop={() => { this.saveSettings(); }}  >
					
					<div style={{display:'flex'}}>

						<div className="treefolder" style={{width:"20%",borderRadius:'0px 10px 0px 0px'}}> 

							<div style={{display:"flex",textAlign:"center"}}>
								<div onClick={ () => this.newFolder() } className="f_tcp hvr-grow" style={{width:"50%"}}><i class="fas fa-folder-plus"></i> Pasta</div>
								<div onClick={ () => this.newFile() } className="f_tcp hvr-grow" style={{width:"50%"}}><i class="fas fa-file-medical"></i> Arquivo</div>
							</div>

							{

								(this.state.showTree === true) &&
								<Treebeard data={this.state.data} onToggle={this.onToggle} /> 

							}
							
							<div style={{padding:'0px 10px',position:'absolute',zIndex:'999',top:'calc(100% - 200px)',width:"inherit"}}>

								{

									(this.state.dirClicked !== undefined && this.state.dirClicked !== "") &&
									<React.Fragment>
										<button onClick={() => this.renameFile() } class="btn btn-primary btn-sm" style={{width:'100%',marginBottom:"5px"}}>Renomear</button>
										<button hidden class="btn btn-primary btn-sm" style={{width:'100%',marginBottom:"5px"}}>Mover</button>
										<button onClick={ () => this.deleteFile() } class="btn btn-danger btn-sm" style={{width:'100%',marginBottom:"5px"}}><i class="far fa-trash-alt"></i> Excluir</button>
									</React.Fragment>

								}
								 

								<button onClick={ () => this.newFileUpload() } class="btn btn-primary btn-sm" style={{width:'100%',marginBottom:"5px"}}><i class="fas fa-file-upload"></i> Enviar arquivo</button>

								<button onClick={ () => this.getTreeFolder(true) } class="btn btn-success btn-sm" style={{width:'100%',marginBottom:"5px"}}><i class="fas fa-sync-alt"></i> Atualizar arquivos</button>
								 


							</div> 

						</div>
						
						<div style={{width:"80%"}}>
 
							<div style={{
								width:'191px',
								position:'absolute',
								left:'calc(100% - 195px)',
								display:'flex',
								zIndex:"999"
							}}>
								<button onClick={ () => { this.saveFile(); }} class="btn btn-primary btn-sm"><i class="far fa-save"></i> Salvar</button>
								<button style={{marginLeft:'5px'}} onClick={ () => {

									var game = this.state.game;
									game.fontSize = parseInt(game.fontSize)-1;
									this.setState({game});

								}} class="btn btn-secondary  btn-sm">Font -</button>
								<button onClick={ () => {

										var game = this.state.game;
										game.fontSize = parseInt(game.fontSize)+1;
										this.setState({game});

								}} class="btn btn-secondary btn-sm" style={{marginLeft:'4px'}}>Font +</button>
							</div>
						
							<TableFiles state={this.state} setState={(data) => this.setState(data)} openFiles={this.state.openFiles} />
							
							{

								(this.state.openFile !== undefined && isimg === false) &&
								
								<ControlledEditor 

									height="90vh"
									language={ext}
									value={this.state.defaultAceEditorValue}
									onChange={ (d,a) => this.aceEditorChange(a) }
									theme="dark"
									
									options={{
										fontSize:this.state.game.fontSize,
										suggest:true,
										CompletionConfiguration:true,
										autoClosingBrackets:true,
										autoClosingOvertype:true,
										autoClosingQuotes:true,
										quickSuggestions:true,
										tabCompletion:true
									}}

								/> 
								
							}
							{

								(this.state.openFile === undefined) &&
								<React.Fragment>

									<center style={{color:'#fff',fontSize:'32px',marginTop:'190px'}}>Escolha um arquivo para poder editar.</center>

								</React.Fragment>

							}

							{

								(isimg === true) &&
								<React.Fragment>
									<center>
									<img style={{maxHeight:'300px',marginTop:'80px'}} className="ddd_ppp" src={sessionStorage.getItem('backend')+'/uploads/users/'+this.state.game.user+'/'+this.state.game.id+''+this.state.openFile} />
									</center>
								</React.Fragment>

							}

						</div>

					</div> 

					</Resizable>
				}
				 
			</div> 


			<div className="modal fade" id="editaProjetoModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				    <br/>
			    	<center><h3>Editar projeto.</h3></center>
				      	<div className="modal-body">
					        
					        <form id="formCadastroGame" onSubmit={ (event) => { this.saveSettings(); event.preventDefault(); } }>
		                    
			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail1">Nome do projeto:</label>
			                      <input onChange={(event) => { this.state.game.name = event.target.value; this.setState({a:""}); }} value={this.state.game.name} required type="text" name="name" className="form-control" /> 
			                    </div>
 
			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail2">Descrição:</label>
			                      <textarea onChange={(event) => { this.state.game.description = event.target.value; this.setState({a:""}); }} value={this.state.game.description} type="text" name="name" className="form-control" ></textarea>
			                    </div>

								<button onClick={ () => this.removeProject() } type="button" className="btn btn-danger" style={{}} >Excluir projeto <i class="far fa-trash-alt"></i></button>
			                    <button type="submit" className="btn btn-primary" style={{float:"right"}} >Salvar alterações <i className="fas fa-plus"></i></button>

			                </form>

				      	</div> 

				    </div>
				</div>
			</div>

			<div className="modal fade" id="novaPastaModal" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				    <br/>
			    	<center><h3>Nova pasta</h3></center>
				      	<div className="modal-body">
					        <form onSubmit={ (event) => { this.postFolder(); event.preventDefault(); } }>
			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail1">Caminho:</label>
			                      <input onChange={(event) => { 

									this.state.folder.dir = event.target.value; 
									this.setState({a:""}); 

									}} value={this.state.folder.dir} required type="text" name="dir" className="form-control" /> 
			                    </div>
			                    <button type="submit" className="btn btn-primary" style={{width:'100%'}} >Criar pasta <i className="fas fa-plus"></i></button>
			                </form>
				      	</div> 
				    </div>
				</div>
			</div>


			<div className="modal fade" id="novaFileModal" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				    <br/>
			    	<center><h3>Novo arquivo</h3></center>
				      	<div className="modal-body">
					        <form onSubmit={ (event) => { this.postFile(); event.preventDefault(); } }>
			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail1">Caminho:</label>
			                      <input onChange={(event) => { 

									this.state.file.dir = event.target.value; 
									this.setState({a:""}); 

									}} value={this.state.file.dir} required type="text" name="dir" className="form-control" /> 
			                    </div>
			                    <button type="submit" className="btn btn-primary" style={{width:'100%'}} >Criar arquivo <i className="fas fa-plus"></i></button>
			                </form>
				      	</div>
				    </div>
				</div>
			</div>



			<div className="modal fade" id="renameModal" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				    <br/>
			    	<center><h3>Renomear</h3></center>
				      	<div className="modal-body">
					        <form onSubmit={ (event) => { this.postRenameFile(); event.preventDefault(); } }>
			                    <div className="form-group">
			                      <label htmlFor="exampleInputEmail1">Novo nome:</label>
			                      <input onChange={(event) => { 

									this.state.rename = event.target.value; 
									this.setState({a:""}); 

									}} value={this.state.rename} required type="text" name="dir" className="form-control" /> 
			                    </div>
			                    <button type="submit" className="btn btn-primary" style={{width:'100%'}}>Renomear arquivo</button>
			                </form>
				      	</div>
				    </div>
				</div>
			</div>



			<div className="modal fade" id="newFileModal" aria-hidden="true" >
				<div className="modal-dialog"  role="document">
				    <div className="modal-content">
				    <br/>
			    	<center><h3>Enviar arquivo</h3></center>
				      	<div className="modal-body">
					        <form id="formUpFile" onSubmit={ (event) => { this.postNewFile(); event.preventDefault(); } }>
			                    
								<div className="form-group">
									<label htmlFor="exampleInputEmail1">Arquivo:</label>
									<input id="fileup" type="file"  /> 
								</div>

								<div className="form-group">
									<label htmlFor="exampleInputEmail1">Pasta:</label>
									<input onChange={(event) => { 

									this.state.rename = event.target.value; 
									this.setState({a:""}); 

									}} value={this.state.rename} id="dir_upload" required type="text" name="dir" className="form-control" /> 
								</div>
								
			                    <button type="submit" className="btn btn-primary" style={{width:'100%'}}>Enviar</button>
			                </form>
				      	</div>
				    </div>
				</div>
			</div>


		</React.Fragment>);

  	}


}

 


export default Blockly;