import React, { Component } from 'react';
import { SortablePane, Pane } from 'react-sortable-pane';
import api from './../Api';
import Tooltip  from 'rc-tooltip';
//import $ from 'jquery';


require('dotenv').config();
 
class Assets extends Component {

    constructor(props){ 

      super(props);
      this.state = {SortablePane:true,order:[],simulaDrag:false};
      this.updateOrder = this.updateOrder.bind(this);
      this.changeEditorType = this.changeEditorType.bind(this);

      window.assets_a = this;

    } 
 
    renderAudio(value){

      //var url = process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_PORT+value.file.url;
      //var url = value.file.url;
      var url = sessionStorage.getItem('backend')+value.upload.url;

      return(
        <div className="boxAsset audioAsset">
          <center><img style={{height:'30px'}} src="/media/play.png" onClick={ () => window.lity(url) } alt=""/></center>
        </div>)

    }

    renderImage(value){

      //var url = process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_PORT+value.file.url;
      //var url = value.file.url;
      var url = sessionStorage.getItem('backend')+value.upload.url;

      return(
        <div className="boxAsset imageAsset" idc={value.id}>

          <img src={url} onClick={ () => window.lity(url) }  alt=""/>

        </div>
      )

    }

    updateOrder(){

      this.state.order.forEach(function(item,index,array){

        api.updateAsset(item,{sequence:index}).then((res) => {
        
        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            return false;

        });

      });

    }

    mostraTableAsset(id){
      
      this.setState({assetAbaDefault:id});
      setTimeout(function(){ window.studio_blockly.saveSettings(); }, 200);
          

    }


    //para mudar o tipo de editor caso tenha arquivo relacionado
    changeEditorType(file,tipo){

      console.log('file: '+file);
      console.log('tipo: '+tipo);

    }


    simulateDrag(id){

      if(id === "remove"){

        this.setState({simulaDrag:false});
        window.$("#simula_drag").remove();
        return false;
        
      }

      var asset = window.$('.imageAsset[idc='+id+']');
      console.log('.imageAsset[idc='+id+']');

      var src = asset.find('img').attr('src');

      this.setState({simulaDrag:true});

      if(window.$('#simula_drag').length === 0){

        window.$("#divIframePhaser").append('  <img id="simula_drag" src="'+src+'" style="position:absolute;pointer-events:none;left:0px;top:0px;"/>  ');

      }else{

        this.setState({simulaDrag:false});
        window.$("#simula_drag").remove();

      }
      //se o elemento existe, eu crio


    }


  	render(){
 	 

      var assets = {};
      window.defaultAssetsType.map((value,index)=>{

        assets[value.name] = [];
        window.studio_blockly.state.Assets.map((value2,index2) => {
  
          if(parseInt(value2.type) === parseInt(value.id)){ assets[value.name].push(value2); }
          return "";

        });

        return "";
        
      });

      
      var className;
      var className2;

      var cp1,cp2;
      if(window.studio_blockly.state.game.advanced){

        cp1 = "col-md-3";
        cp2 = "col-md-2";

      }else{

        cp1 = "col-md-5";
        cp2 = "col-md-3 hidden_div";

      }

      //console.log(assets);

	    return (<>
	             
              
              <div style={{display:'flex',marginTop:'10px'}}>

                {
 
                  window.defaultAssetsType.map((value,index)=>{

                      if(parseInt(this.state.assetAbaDefault) === parseInt(value.id)){ className="optionHeaderAsset ativo"; }else{ className= "optionHeaderAsset"; }

                      return( <div key={value.id} className={className} onClick={ () => this.mostraTableAsset(value.id) } idc={value.id} style={{width:"50%",textAlign:'center'}}>
                        <i className="far fa-folder-open" style={{marginRight:'3px'}}></i><i className="far fa-folder" style={{marginRight:'3px'}}></i>{value.name}</div> );

                  })

                }

              </div>

             <input hidden id="inputAsset" type="file" onChange={ () => window.studio_blockly.sendAsset() } />
		    
             { (this.state.order.length !== 0 && this.state.SortablePane) &&  

              window.defaultAssetsType.map((value2,index2) => {
              
              if(parseInt(this.state.assetAbaDefault) === parseInt(value2.id)){ className2="optionDivAsset ativo"; }else{ className2= "optionDivAsset"; }

              return (

              <div className={className2} idc={value2.id} key={value2.id} >

              {

                (assets[value2.name].length !== 0) &&
                <div className="row rowTitlebaixo">
                  <div className="col-md-2"></div>
                  <div className="col-md-2">Preview</div>
                  <div className={cp1}>Key</div>
                  <div className={cp2}>Editor</div>
                  <div hidden>Tipo</div>
                  <div className="col-md-3"><center>Ações</center></div>
                </div>

              }


              {

                (assets[value2.name].length === 0) &&
                <div style={{fontSize:'20px',marginTop:'15px'}}><center>{value2.t} {value2.name2} foi adicionado.</center></div>

              }


              <SortablePane resizable={{x:false,y:false,xy:false}}   dragHandleClassName="movedor" direction="vertical" margin={5} onOrderChange={order => { this.setState({ order }); }} onDragStop={order => { this.updateOrder(); }}  >
                
              {

                assets[value2.name].map((value,index) => {
  
                //var url = process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_PORT+value.file.url;
                //var url = value.file.url;
              

//                console.log(value);

                return( <Pane resizable={{x:false,y:false,xy:false}} className="__resizable_base__" key={value.id} defaultSize={{ width: '100%',height:'40px'}} minHeight="40px">
               
                      <div className="row asset" tipo={value.file.ext}>

                        <div className="col-md-2" style={{whiteSpace:'nowrap'}}>
                          
                          <center>

                            <i className="fas fa-arrows-alt movedor" style={{position:'absolute',top:'13px'}}></i>

                            {

                              (value.gamefiles !== null && value.gamefiles !== "" && value.type !== 3) &&
                              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Editar programação</span>}>
                                <i onClick={ () => window.studio_blockly.preGetFile(value.file.id) } className="fas fa-pencil-alt" style={{position:'absolute',top:'13px',marginLeft: '20px',cursor:'pointer'}}></i>
                              </Tooltip>
                            
                            }
                            

                          </center>

                        </div> 

                        <div className="col-md-2">

                          {

                            (value.upload.ext === "svg" || value.upload.ext === "jpg" || value.upload.ext === "png" || value.upload.ext === "gif") &&
                            this.renderImage(value)

                          }

                          {

                            (value.upload.ext === "mp3") &&
                            this.renderAudio(value)

                          }

                        </div>

                        <div className={cp1}> <input onKeyUp={ (event) => window.studio_blockly.updateNameAsset(value.id,event.target.value) } file_id={value.file.id} className="form-control input_baixo" defaultValue={value.name} style={{position:'absolute',top:'7px'}} /></div>
                        
                        <div className={cp2}>

                          <select defaultValue={value.type} onChange={ (event) => window.studio_blockly.updateFile(value.gamefiles.id,'editor',event.target.value) } className="selectTipoEditor" style={{top:'7px',position: 'absolute'}}> 

                            {   

                              window.defaultEditorOptions.map(function(item, i){
                                
                                return <option key={i} value={i} >{item}</option>

                              })

                            }

                          </select> 

                        </div>


                        <div className="col-md-2" hidden> 

                          <select defaultValue={value.type} onChange={ (event) => this.changeEditorType(value.gamefiles.id,event.target.value) } className="selectTipoEditor" style={{top:'7px',position: 'absolute'}}> 

                            {   

                              window.defaultAssetsType.map(function(item, i){
                                
                                return <option key={item.id} value={item.id} >{item.name}</option>

                              })

                            }

                          </select> 

                        </div>

                        <div className="col-md-3 tes"> 
                          <center> 
                              
                              {

                                (parseInt(value.type) === 1) &&
                                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Configurar animações</span>}>
                                  <i onClick={ () => window.animationModal.openAnimationModal(value.id) } className="fas fa-cog" style={{marginLeft:'3px',cursor:'pointer',position:'relative',top:'8px'}}></i>
                                </Tooltip>

                              }

                              {

                                (window.studio_blockly.state.game.advanced === true) &&
                                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Associação</span>}>
                                  <i onClick={ () => window.AssocitionModal.openAssocitionModal(value.id) } className="fa fa-code" style={{marginLeft:'5px',cursor:'pointer',position:'relative',top:'8px'}}></i>
                                </Tooltip>

                              }

                              {

                                (parseInt(value.type) !== 3) &&
                                <Tooltip hidden placement="bottom" trigger={['hover']} overlay={<span>Importar asset</span>}>
                                  <i onClick={() =>  window.ImportAsseAsset.openModalImportAsseAsset(value.id) } className="fab fa-connectdevelop" style={{cursor:'pointer',position:'relative',top:'8px',marginLeft:'5px'}}></i> 
                                </Tooltip>

                              }
                              
                              
                              <Tooltip hidden placement="bottom" trigger={['hover']} overlay={<span>Link externo</span>}>
                                <i onClick={() =>  window.open(sessionStorage.getItem('backend')+value.upload.url, '_blank')} className="fas fa-external-link-alt" style={{cursor:'pointer',position:'relative',top:'8px',marginLeft:'5px'}}></i> 
                              </Tooltip>

                               {

                                /*<Tooltip hidden placement="bottom" trigger={['hover']} overlay={<span>Simulação de cordenadas</span>}>
                                <i className="fas fa-arrows-alt" style={{marginLeft:'3px',cursor:'pointer',position:'relative',top:'8px'}} onClick={ () => window.assets_a.simulateDrag(value.id) }></i>
                              </Tooltip>*/

                               }

                              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Remover asset</span>}>
                                <i className="far fa-trash-alt" style={{marginLeft:'3px',cursor:'pointer',position:'relative',top:'8px'}} onClick={ () => window.studio_blockly.removeAsset(value.id) }></i>
                              </Tooltip>
 

                          </center>
                        </div>

                      </div>
                    
                  </Pane>)



              })


                }

              </SortablePane></div>)

               })



            }

            { (window.studio_blockly.state.Assets.length === 0) &&

              <div className="nenhumlog animated fadeIn"><font>Nenhum asset foi enviado.</font></div>

            }

	  	</>);

  	}


}

export default Assets;