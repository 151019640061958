import React, { Component } from 'react';
 

//import brace from 'brace';
import AceEditor from 'react-ace';
import api from './Api';

import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';

import 'brace/snippets/html';
import 'brace/ext/language_tools';

class Help extends Component {
  
  constructor(props){

    super(props); 

    this.state = {value:"",carregou:false};

    this.getStudioHelper = this.getStudioHelper.bind(this);
    this.onChangeAceEditor = this.onChangeAceEditor.bind(this);

  }
  componentDidMount(){

  	window.app.loading(false);
    this.getStudioHelper();

  }

  async getStudioHelper(){

    await api.getStudioHelper().then((res) => {

        this.setState({value:res.data,carregou:true});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
    });


  }

 async onChangeAceEditor(value){

    await api.setStudioHelper(value).then((res) => {

        //this.setState({value:res.data,carregou:true});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
    });

  }

  

  render(){ 

  	return(<div className="container">

  		<link rel="stylesheet" href="/css/help.css" type="text/css" media="all" />

  		<div className="col-md-12">

  			<div className="col-md-12 fontPadrao">

  				Retornas todos os assets importados pela scenes: 
  				<font className="codeColor"> <strong>window.studio.returnImportFiles(file,type){}</strong></font><br/>
  
  			</div>

  			<div className="col-md-12 fontPadrao">Retorna todos os arquivos: <font className="codeColor"><strong>window.studio.returnAllFiles(){}</strong></font> </div>

  			<div className="col-md-12 fontPadrao">Retorna somente as scenes: <font className="codeColor"><strong>window.studio.returnAllScenes(){}</strong></font> </div>

        <div className="col-md-12 fontPadrao">Retorna somente as scenes: <font className="codeColor"><strong>window.studio.returnAllScenes(){}</strong></font> </div>

  			<div className="col-md-12 fontPadrao">

  				<strong>file</strong> = id do file<br/>
  				<strong>type</strong> = tipo do arquivo (0,1,2,3)<br/><br/>
				  <br/>
  				Tipos de arquivos:<br/><br/>
  				<strong>0</strong> = Imagens<br/>
  				<strong>1</strong> = SpriteSheet<br/>
  				<strong>2</strong> = Sprite<br/>
  				<strong>3</strong> = Aúdio<br/>

  			</div>
       
        <div className="col-md-12 fontPadrao">Retorna o xml do arquivo atual: <font className="codeColor"><strong> window.Blockly.Xml.workspaceToDom(window.demoWorkspace); </strong></font> </div>
  			
  		  
        <div className="col-md-12 fontPadrao">Retorna o javascript do arquivo atual: <font className="codeColor"><strong> window.Blockly.JavaScript.workspaceToCode(window.demoWorkspace); </strong></font> </div>

        <div className="col-md-12 fontPadrao">Retorna o javascript do arquivo atual filtrado pela engine (adiciona e importa class no topo): <font className="codeColor"><strong> window.studio_blockly.geraJS(); </strong></font> </div>
        

        <div className="col-md-12 fontPadrao">Retorna o OBJ do arquivo atual: <font className="codeColor"><strong> window.studio_blockly.getFileState(window.studio_blockly.state.game.fileSelected); </strong></font> </div>
          
        <div className="col-md-12 fontPadrao">Para ocultar um arquivo: <font className="codeColor"><strong> window.studio_blockly.ocultarFile(ID_ARQUIVO); </strong></font> </div>

        <div className="col-md-12 fontPadrao">Para desocultar um arquivo: <font className="codeColor"><strong> window.studio_blockly.desocultarFile(ID_ARQUIVO); </strong></font> </div>

        <div className="col-md-12 fontPadrao">Para criar um arquivo do tipo json: <font className="codeColor"><strong> window.studio_blockly.createFile('nome_do_arquivi','json',JSON.stringify({'{teste:"funcionando"}'})); </strong></font> </div>
  
        <div className="col-md-12 fontPadrao">Para atualizar um arquivo: <font className="codeColor"><strong> window.studio_blockly.updateFile(id,coluna,value); </strong></font> </div>
 
        <div className="col-md-12 fontPadrao">Para remover qualquer arquivo: <font className="codeColor"><strong> window.studio_blockly.removeFileApi('id_do_arquivo'); </strong></font> </div>
           
        <br/><br/>

        <font className="fontPadrao"> O código abaixo será injetado no studio, antes do carregamento do blockly.<br/> </font>
       
        {

          (this.state.carregou === true) && 
          <AceEditor
            mode="javascript"
            ref="aceEditor"
            theme="monokai" 
            name="aceEditor"
            editorProps={{$blockScrolling: true}}
            width="auto"
            height={500}
            onChange={this.onChangeAceEditor}
            showPrintMargin={false} 
            value={this.state.value}
          />

        }
       

        <br/><br/>


  		</div>



   



  	</div>) 

  }


}

export default Help;